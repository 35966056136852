import React, { useEffect, useState, useRef } from "react";
import L from "leaflet";

// Tạo icon cho các tiện ích
const schoolIcon = L.icon({
  iconUrl: 'https://static.homedy.com/src/images/icon/map/truonghoc4.svg', // Đường dẫn đến icon trường học
  iconSize: [32, 32],
  iconAnchor: [16, 32],
  popupAnchor: [0, -32],
});

const supermarketIcon = L.icon({
  iconUrl: 'https://static.homedy.com/src/images/icon/map/sieuthi4.svg', // Đường dẫn đến icon siêu thị
  iconSize: [32, 32],
  iconAnchor: [16, 32],
  popupAnchor: [0, -32],
});

const hospitalIcon = L.icon({
  iconUrl: 'https://static.homedy.com/src/images/icon/map/yte4.svg', // Đường dẫn đến icon y tế
  iconSize: [32, 32],
  iconAnchor: [16, 32],
  popupAnchor: [0, -32],
});

const bankIcon = L.icon({
  iconUrl: 'https://static.homedy.com/src/images/icon/map/nganhang4.svg', // Đường dẫn đến icon ngân hàng
  iconSize: [32, 32],
  iconAnchor: [16, 32],
  popupAnchor: [0, -32],
});

// Tạo icon cho vị trí bài đăng
const postLocationIcon = L.icon({
  iconUrl: 'https://static.homedy.com/src/images/location-reviews/pin4.svg', // Đường dẫn đến icon vị trí bài đăng
  iconSize: [35, 45], // Kích thước biểu tượng
  iconAnchor: [17.5, 45], // Điều chỉnh điểm neo
  popupAnchor: [0, -45], // Điều chỉnh vị trí popup
});

const busStationIcon = L.icon({
  iconUrl: 'https://static.homedy.com/src/images/icon/map/benxe4.svg', // Đường dẫn đến icon bến xe
  iconSize: [32, 32],
  iconAnchor: [16, 32],
  popupAnchor: [0, -32],
});

// Hàm gọi Overpass API để lấy các tiện ích xung quanh
const fetchNearbyAmenities = async (lat, lon) => {
  const radius = 6000; // Extend radius to 1km for better coverage
  const query = `
    [out:json];
    (
      node(around:${radius},${lat},${lon})[amenity~"kindergarten|school|college|university"];  // Schools
      node(around:${radius},${lat},${lon})[shop~"supermarket|convenience|mall|market"];  // Supermarkets
      node(around:${radius},${lat},${lon})[amenity~"hospital|clinic|pharmacy"];  // Hospitals
      node(around:${radius},${lat},${lon})[amenity~"bank|atm"];  // Banks
      node(around:${radius},${lat},${lon})[amenity~"bus_station|bus_stop"];  // Bus stations
    );
    out body;
  `;
  const url = `https://overpass-api.de/api/interpreter?data=${encodeURIComponent(query)}`;

  try {
    const response = await fetch(url);
    const data = await response.json();
    return data.elements; // Return nearby amenities
  } catch (err) {
    console.error("Error fetching nearby amenities:", err);
  }
};

const MapWithAmenities = ({ coords }) => {
  const [selectedCategory, setSelectedCategory] = useState("schools");
  const mapRef = useRef(null); // Reference to the map instance
  const markersRef = useRef([]); // Reference to keep track of all markers

  // Function to clear all existing markers
  const clearMarkers = () => {
    markersRef.current.forEach((marker) => {
      marker.remove(); // Remove marker from the map
    });
    markersRef.current = []; // Reset the marker array
  };

  useEffect(() => {
    // Initialize the map only once
    if (!mapRef.current && coords.lat && coords.lng) {
      mapRef.current = L.map("map").setView([coords.lat, coords.lng], 15);

      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png").addTo(mapRef.current);

      // Thêm marker cho vị trí bài đăng với biểu tượng đã chỉnh sửa
      L.marker([coords.lat, coords.lng], { icon: postLocationIcon }) // Sử dụng biểu tượng mới cho vị trí bài đăng
        .addTo(mapRef.current)
        .bindPopup("Vị trí của bài đăng")
        .openPopup();
    }

    // Fetch nearby amenities and update markers when coords or selectedCategory changes
    if (coords.lat && coords.lng) {
      clearMarkers(); // Clear existing markers before adding new ones

      fetchNearbyAmenities(coords.lat, coords.lng).then((amenities) => {
        const categories = {
          schools: [],
          supermarkets: [],
          hospitals: [],
          banks: [],
          busStations: [], 
        };

        amenities.forEach((amenity) => {
          const { amenity: type, shop } = amenity.tags;

          if (
            type === "kindergarten" ||
            type === "school" ||
            type === "college" ||
            type === "university"
          ) {
            categories.schools.push(amenity);
          } else if (
            shop === "supermarket" ||
            shop === "convenience" ||
            shop === "mall" ||
            shop === "market"
          ) {
            categories.supermarkets.push(amenity);
          } else if (type === "hospital" || type === "clinic" || type === "pharmacy") {
            categories.hospitals.push(amenity);
          } else if (type === "bank" || type === "atm") {
            categories.banks.push(amenity);
          } else if(type === "bus_station" || type === "bus_stop") {
            categories.busStations.push(amenity);
          }
          
        });

        Object.keys(categories).forEach((category) => {
          if (selectedCategory === "all" || selectedCategory === category) {
            categories[category].forEach((amenity) => {
              let icon;
              switch (category) {
                case "schools":
                  icon = schoolIcon;
                  break;
                case "supermarkets":
                  icon = supermarketIcon;
                  break;
                case "hospitals":
                  icon = hospitalIcon;
                  break;
                case "banks":
                  icon = bankIcon;
                  break;
                case "busStations":
                  icon = busStationIcon; 
                  break
                default:
                  icon = schoolIcon; // Default icon if needed
              }

              const marker = L.marker([amenity.lat, amenity.lon], { icon }).addTo(mapRef.current);
              marker.bindPopup(amenity.tags.name || "Không có tên");
              markersRef.current.push(marker); // Add marker to the markers array
            });
          }
        });
      });
    }

    // Cleanup function to remove the map instance and markers on unmount
    return () => {
      if (mapRef.current) {
        mapRef.current.remove();
        mapRef.current = null; // Reset map instance reference
      }
    };
  }, [coords, selectedCategory]);

  return (
    <div>
       <style>
        {`
          .filter-tabs {
            display: flex;
            justify-content: space-between;
            margin: 20px 0;
          }

          .filter-tabs button {
            background-color: #007bff; /* Màu nền chính */
            color: white; /* Màu chữ */
            border: none; /* Không viền */
            border-radius: 5px; /* Bo góc */
            padding: 5px 20px; /* Khoảng cách bên trong */
            margin: 0 10px; /* Khoảng cách giữa các nút */
            cursor: pointer; /* Con trỏ khi hover */
            transition: background-color 0.3s ease; /* Hiệu ứng chuyển màu */
          }

          .filter-tabs button:hover {
            background-color: #0056b3; /* Màu nền khi hover */
          }

          .filter-tabs button.active {
            background-color: #004085; /* Màu nền cho nút đang được chọn */
            color: white; /* Màu chữ cho nút đang được chọn */
          }
        `}
      </style>
      <div className="filter-tabs">
        {/* <button onClick={() => setSelectedCategory("all")}>Tất cả</button> */}
        <button onClick={() => setSelectedCategory("schools")}>Trường học</button>
        <button onClick={() => setSelectedCategory("supermarkets")}>Siêu thị</button>
        <button onClick={() => setSelectedCategory("hospitals")}>Y tế</button>
        <button onClick={() => setSelectedCategory("banks")}>Ngân hàng</button>
        <button onClick={() => setSelectedCategory("busStations")}>Bến xe</button> 
      </div>
      <div id="map" style={{ height: "300px", width: "100%" }} />
    </div>
  );
};

export default MapWithAmenities;
