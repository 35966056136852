import React from "react";
import { Navigation } from "../Public";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { path } from "../../ultils/constant";
import { formatVietnameseToString } from "../../ultils/Common/formatVietnameseToString";

const notActive =
  "hover:bg-secondary2 px-4 h-full flex items-center bg-secondary1";
const active =
  "hover:bg-secondary2 px-4 h-full flex items-center bg-secondary2";

const Header = ({ isAdmin }) => {
  const dispatch = useDispatch();
  const { categories } = useSelector((state) => state.app);
  const { currentData } = useSelector((state) => state.user);
  
  return (
    <div className="w-full flex flex-none h-[40px] margin-right">
      <div className="flex justify-center items-center font-bold bg-secondary1 text-white w-[256px] flex-none">
        Nestify-fe.techtheworld.id.vn
      </div>
      <div className="flex-auto">
        <div
          className={`w-full flex ${
            isAdmin ? "justify-start" : "justify-center"
          } items-center h-[40px] bg-secondary1 text-white`}
        >
          <div className="w-5/5 flex h-full items-center text-sm font-medium mr-20">
            <NavLink
              to={`/`}
              className={({ isActive }) =>
                `${isActive ? active : notActive} flex-grow text-center px-6`
              } 
              style={{ whiteSpace: "nowrap" }} 
            >
              Trang chủ
            </NavLink>
            {categories?.length > 0 &&
              categories.map((item) => (
                <div
                  key={item.code}
                  className="h-full flex justify-center items-center flex-grow"
                >
                  <NavLink
                    to={`/${formatVietnameseToString(item.value)}`}
                    className={({ isActive }) =>
                      `${isActive ? active : notActive} flex-grow text-center px-6`
                    }
                    style={{ whiteSpace: "nowrap" }} 
                  >
                    {item.value}
                  </NavLink>
                </div>
              ))}
            <NavLink
              to={path.CONTACT}
              className={({ isActive }) =>
                `${isActive ? active : notActive} flex-grow text-center px-6`
              }
              style={{ whiteSpace: "nowrap" }} 
            >
              Liên hệ
            </NavLink>
            {currentData.id && (
              <NavLink
                to={`/${path.WISHLIST}`}
                className={({ isActive }) =>
                  `${isActive ? active : notActive} flex-grow text-center px-6`
                }
                style={{ whiteSpace: "nowrap" }} 
              >
                Bài đăng yêu thích
              </NavLink>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
