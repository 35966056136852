const actionTypes = {
    LOGIN: 'LOGIN',
    REGISTER_SUCCESS: 'REGISTER_SUCCESS',
    REGISTER_FAIL: 'REGISTER_FAIL',
    LOGOUT: 'LOGOUT',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAIL: 'LOGIN_FAIL',

    GET_POSTS: "GET_POSTS",
    GET_POSTS_LIMIT: "GET_POSTS_LIMIT",
    GET_POSTS_ADMIN: 'GET_POSTS_ADMIN',
    GET_NEW_POST: "GET_NEW_POST",
    EDIT_DATA: 'EDIT_DATA',
    RESET_DATAEDIT: 'RESET_DATAEDIT',
    GET_OUTSTANDING: 'GET_OUTSTANDING',

    GET_CATEGORIES: 'GET_CATEGORIES',
    GET_PRICES: 'GET_PRICES',
    GET_AREAS: 'GET_AREAS',
    GET_PROVINCES: 'GET_PROVINCES',

    // USER
    GET_CURRENT: 'GET_CURRENT'
}

export default actionTypes