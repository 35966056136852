import React, { useEffect, useState } from 'react'
import { PageNumber } from '../../components'
import icons from '../../ultils/icons'
import { useSearchParams } from 'react-router-dom'

const { GrLinkNext, GrLinkPrevious } = icons

const Pagination = ({ count, posts }) => {
    const [arrPage, setArrPage] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [searchParams] = useSearchParams()

    useEffect(() => {
        let page = searchParams.get('page')
        page && +page !== currentPage && setCurrentPage(+page)
        !page && setCurrentPage(1)
    }, [searchParams])

    useEffect(() => {
        const maxPage = Math.ceil(count / process.env.REACT_APP_LIMIT_ADMIN)
        let end = currentPage + 2 > maxPage ? maxPage : currentPage + 2
        let start = currentPage - 2 <= 1 ? 1 : currentPage - 2
        let temp = []
        for (let i = start; i <= end; i++) temp.push(i)
        setArrPage(temp)
    }, [count, posts, currentPage])

    const maxPage = Math.ceil(count / process.env.REACT_APP_LIMIT_ADMIN)

    return (
        <div className='flex items-center justify-center gap-2 py-5'>
            {currentPage > 1 && (
                <PageNumber 
                    icon={<GrLinkPrevious />} 
                    setCurrentPage={() => setCurrentPage(currentPage - 1)} 
                    text={currentPage - 1} 
                />
            )}

            {currentPage > 3 && (
                <PageNumber 
                    setCurrentPage={setCurrentPage} 
                    text={1} 
                />
            )}

            {currentPage > 3 && <PageNumber text={'...'} />}

            {arrPage.length > 0 && arrPage.map(item => (
                <PageNumber
                    key={item}
                    text={item}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                />
            ))}

            {currentPage < maxPage - 2 && <PageNumber text={'...'} />}

            {currentPage < maxPage - 2 && (
                <PageNumber 
                    setCurrentPage={setCurrentPage} 
                    text={maxPage} 
                />
            )}

            {currentPage < maxPage && (
                <PageNumber 
                    icon={<GrLinkNext />} 
                    setCurrentPage={() => setCurrentPage(currentPage + 1)} 
                    text={currentPage + 1} 
                />
            )}
        </div>
    )
}

export default Pagination
