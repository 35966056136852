import React, { memo, useState } from "react";
import icons from "../ultils/icons";
import { Link } from "react-router-dom";
import { formatVietnameseToString } from "../ultils/Common/formatVietnameseToString";
import { path } from "../ultils/constant";
import { apiUpdateWishlist } from "../services/post";
import { toast } from "react-toastify";

const { GrStar, RiHeartFill, RiHeartLine, BsBookmarkStarFill } = icons;

const Item = ({
  images,
  user,
  title,
  star,
  description,
  attributes,
  address,
  id,
  islover,
  setUpdate,
}) => {
  const handleStar = (star) => {
    let stars = [];
    for (let i = 1; i <= +star; i++)
      stars.push(<GrStar className="star-item" size={18} color="yellow" />);
    return stars;
  };
  const handleUpdateWishlist = async (e) => {
    e.stopPropagation();
    const response = await apiUpdateWishlist({ pid: id });
    if (response.data?.err === 0) {
      setUpdate((prev) => !prev);
      toast.success(response.data.msg);
    }
  };
  return (
    <div className="w-full flex border-t border-orange-600 py-4 relative">
      <Link
        to={`${path.DETAIL}${formatVietnameseToString(
          title?.replaceAll("/", "")
        )}/${id}`}
        className={`w-2/5 grid ${
          images?.length >= 4
            ? "grid-cols-2 grid-rows-2"
            : "grid-cols-1 grid-rows-1"
        } gap-[2px] items-center relative cursor-pointer mr-3`}
        style={{ aspectRatio: "1/1", position: "relative" }}
      >
        {images &&
          images?.length > 0 &&
          (images.length >= 4 ? (
            images
              .slice(0, 4)
              .map((i, index) => (
                <img
                  key={index}
                  src={i}
                  alt={`preview-${index}`}
                  className="w-full h-full object-cover"
                />
              ))
          ) : (
            <img
              src={images[0]}
              alt="preview"
              className="w-full h-full object-cover"
            />
          ))}
        <span className="bg-overlay-70 text-white px-2 rounded-md absolute left-1 bottom-4">{`${images?.length} ảnh`}</span>
      </Link>
      <span
        className="absolute bottom-2 right-2 p-1 left-[32%] bottom-[8%] text-white"
        style={{ index: 999 }}
        onClick={(e) => {
          e.stopPropagation();
          handleUpdateWishlist(e);
        }}
      >
        {islover ? (
          <RiHeartFill size={26} color="red" />
        ) : (
          <RiHeartLine size={26} />
        )}
      </span>
      <div className="w-3/5">
        <div className="flex justify-between gap-4 w-full">
          <Link
            to={`${path.DETAIL}${formatVietnameseToString(
              title?.replaceAll("/", "")
            )}/${id}`}
            className="text-red-600 font-medium"
          >
            {handleStar(+star).length > 0 &&
              handleStar(+star).map((star, number) => {
                return <span key={number}>{star}</span>;
              })}
            {title}
          </Link>
        </div>
        <div className="my-2 flex items-center justify-between gap-2">
          <span className="font-bold flex-3 text-green-600  whitespace-nowrap overflow-hidden text-ellipsis">
            {attributes?.price}
          </span>
          <span className="flex-1">{attributes?.acreage}</span>
          <span className="flex-3 whitespace-nowrap overflow-hidden text-ellipsis">
            {`${address?.split(",")[address?.split(",").length - 2]}${
              address?.split(",")[address?.split(",").length - 1]
            }`}
          </span>
        </div>
        {/* <p className='text-gray-500 w-full h-[50px] text-ellipsis overflow-hidden'>
                    {description}
                </p> */}
        <div className="flex items-center my-5 justify-between">
          <div className=" flex items-center">
            <img
              src={`${user?.avatar}`}
              alt="avatar"
              className="w-[30px] h-[30px] object-cover rounded-full"
            />
            <p>{user?.name}</p>
          </div>
          <div className="flex items-center gap-1">
            <a
              className="bg-orange-700 text-white p-1 rounded-md"
              href={`${path.DETAIL}${formatVietnameseToString(
                title?.replaceAll("/", "")
              )}/${id}`}
              target="_blank"
            >
              Xem chi tiết
            </a>
            <a
              className="text-orange-700 px-1 rounded-md border border-orange-700"
              href={`https://zalo.me/0942850163`}
              target="_blank"
            >
              Nhắn zalo
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Item);
