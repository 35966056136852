import React, { useCallback, useEffect, useRef, useState } from 'react';
import logo from '../../assets/logo_nestify.png';
import { Button, User } from '../../components';
import icons from '../../ultils/icons';
import { useNavigate, Link, useSearchParams, useLocation, NavLink } from 'react-router-dom';
import { path } from '../../ultils/constant';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../store/actions';
import menuManage from '../../ultils/menuManage';
import menuAdmin from '../../ultils/menuAdmin';
import { formatVietnameseToString } from '../../ultils/Common/formatVietnameseToString';
const notActive = 'px-4 h-full flex items-center text-orange-700 hover:text-orange-500';
const active = 'px-4 h-full flex items-center text-orange-700 border-b-2 border-orange-500';
const { AiOutlinePlusCircle, AiOutlineLogout, BsChevronDown } = icons;

const Header = ({ isAdmin }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const headerRef = useRef();
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { currentData } = useSelector((state) => state.user);
  const [isShowMenu, setIsShowMenu] = useState(false);
  const { categories } = useSelector((state) => state.app);

  useEffect(() => {
    dispatch(actions.getCategories());
  }, [dispatch]);
  const goLogin = useCallback(
    (flag) => {
      navigate(path.LOGIN, { state: { flag } });
    },
    [navigate]
  );

  useEffect(() => {
    headerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, [searchParams.get('page'), location.pathname]);

  return (
    <div
      ref={headerRef}
      className="w-full h-[80px] flex items-center bg-orange-100 text-orange-700 px-4 shadow-md"
    >
      <div className="w-full max-w-7xl mx-auto flex items-center justify-between">
        {/* Logo */}
        <Link to={'/'}>
          <img src={logo} alt="logo" className="w-[50px] h-[50px] object-contain" />
        </Link>
        <div className="w-3/5 flex h-full items-center text-sm font-medium">
        <NavLink to={`/`} className={({ isActive }) => (isActive ? active : notActive)}>
          Trang chủ
        </NavLink>
        {categories?.length > 0 &&
          categories.map((item) => {
            return (
              <div key={item.code} className="h-full flex justify-center items-center">
                <NavLink
                  to={`/${formatVietnameseToString(item.value)}`}
                  className={({ isActive }) => (isActive ? active : notActive)}
                >
                  {item.value}
                </NavLink>
              </div>
            );
          })}
        <NavLink to={path.CONTACT} className={({ isActive }) => (isActive ? active : notActive)}>
          Liên hệ
        </NavLink>
        {currentData.id && (
          <NavLink
            to={`/${path.WISHLIST}`}
            className={({ isActive }) => (isActive ? active : notActive)}
          >
            Bài đăng yêu thích
          </NavLink>
        )}
      </div>
        {/* Navigation and User Options */}
        <div className="flex items-center gap-4">
          {!isLoggedIn ? (
            <div className="flex items-center gap-3">
              <div
      className={`w-full flex ${
        isAdmin ? 'justify-start' : 'justify-center'
      } items-center h-[40px] bg-orange-100 text-orange-700`}
    >
      
    </div>
              <Button
                text={'Đăng nhập'}
                textColor="text-white"
                bgColor="bg-orange-500 hover:bg-orange-600"
                onClick={() => goLogin(false)}
              />
              <Button
                text={'Đăng ký'}
                textColor="text-white"
                bgColor="bg-orange-500 hover:bg-orange-600"
                onClick={() => goLogin(true)}
              />
            </div>
          ) : (
            <div className="flex items-center gap-4 relative">
              <User />
              <Button
                text={'Quản lý tài khoản'}
                textColor="text-white"
                bgColor="bg-orange-600 hover:bg-orange-700"
                px="px-4"
                IcAfter={BsChevronDown}
                onClick={() =>
                  currentData?.role === 'R3'
                    ? navigate('/he-thong/sua-thong-tin-ca-nhan')
                    : setIsShowMenu((prev) => !prev)
                }
              />

              {/* Menu for Manager */}
              {isShowMenu && currentData?.role === 'R2' && (
                <div className="absolute min-w-[200px] z-50 top-full bg-white shadow-lg rounded-md p-4 right-0 flex flex-col">
                  {menuManage.map((item) => (
                    <Link
                      className="hover:text-orange-600 flex items-center gap-2 text-orange-500 border-b border-gray-200 py-2 w"
                      key={item.id}
                      to={item?.path}
                    >
                      {item?.icon}
                      {item.text}
                    </Link>
                  ))}
                  <span
                    className="cursor-pointer hover:text-orange-600 text-orange-500 py-2 flex items-center gap-2 "
                    onClick={() => {
                      setIsShowMenu(false);
                      dispatch(actions.logout());
                    }}
                  >
                    <AiOutlineLogout />
                    Đăng xuất
                  </span>
                </div>
              )}

              {/* Menu for Admin */}
              {isShowMenu && currentData?.role === 'R1' && (
                <div className="absolute min-w-[200px] top-full bg-white shadow-lg rounded-md p-4 right-0 flex flex-col">
                  {menuAdmin.map((item) => (
                    <Link
                      className="hover:text-orange-600 flex items-center gap-2 text-orange-500 border-b border-gray-200 py-2"
                      key={item.id}
                      to={item?.path}
                    >
                      {item?.icon}
                      {item.text}
                    </Link>
                  ))}
                  <span
                    className="cursor-pointer hover:text-orange-600 text-orange-500 py-2 flex items-center gap-2"
                    onClick={() => {
                      setIsShowMenu(false);
                      dispatch(actions.logout());
                    }}
                  >
                    <AiOutlineLogout />
                    Đăng xuất
                  </span>
                </div>
              )}
            </div>
          )}
          {(currentData?.role === 'R1' || currentData?.role === 'R2') && (
            <Button
              text={'Đăng tin mới'}
              textColor="text-white"
              bgColor="bg-orange-500 hover:bg-orange-600"
              IcAfter={AiOutlinePlusCircle}
              onClick={() => navigate('/he-thong/tao-moi-bai-dang')}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
