import React, { useEffect, useState } from "react";
import { Overview, Address, Loading, Button } from "../../components";
import { apiUploadImages } from "../../services";
import icons from "../../ultils/icons";
import { getCodes, getCodesArea } from "../../ultils/Common/getCodes";
import { useSelector, useDispatch } from "react-redux";
import { apiCreatePost, apiUpdatePost } from "../../services";
import Swal from "sweetalert2";
import validate from "../../ultils/Common/validateFields";
import { resetDataEdit } from "../../store/actions";
import { attention } from "../../ultils/constant";
import { useDebounce } from "../../HOC/useDebounce";
import axios from "axios";
import { MapContainer, TileLayer, Marker, useMap } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { useNavigate } from "react-router-dom";

const { BsCameraFill, ImBin } = icons;

// Leaflet marker icon fix for missing default icon
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png",
  iconUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png",
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
});

const CreatePost = ({ isEdit }) => {
  const dispatch = useDispatch();
  const nav = useNavigate();
  const [coords, setCoords] = useState({ lat: 0, lng: 0 });
  const { dataEdit } = useSelector((state) => state.post);
  const [payload, setPayload] = useState(() => ({
    categoryCode: dataEdit?.categoryCode || "",
    title: dataEdit?.title || "",
    priceNumber: dataEdit?.priceNumber * 1000000 || 0,
    areaNumber: dataEdit?.areaNumber || 0,
    images: dataEdit?.images?.image ? JSON.parse(dataEdit?.images?.image) : "",
    address: dataEdit?.address || "",
    priceCode: dataEdit?.priceCode || "",
    areaCode: dataEdit?.areaCode || "",
    description: dataEdit?.description ? JSON.parse(dataEdit?.description) : "",
    target: dataEdit?.overviews?.target || "",
    province: dataEdit?.province || "",
  }));
  const [imagesPreview, setImagesPreview] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { prices, areas, categories, provinces } = useSelector(
    (state) => state.app
  );
  const { currentData } = useSelector((state) => state.user);
  const [invalidFields, setInvalidFields] = useState([]);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setCoords({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    });
  }, []);

  useEffect(() => {
    if (dataEdit) {
      const images = JSON.parse(dataEdit?.images?.image);
      images && setImagesPreview(images);
    }
  }, [dataEdit]);

  const addr = useDebounce(payload.address, 500);

  const handleFiles = async (e) => {
    e.stopPropagation();
    setIsLoading(true);
    let images = [];
    let files = e.target.files;
    let formData = new FormData();
    for (let i of files) {
      formData.append("file", i);
      formData.append(
        "upload_preset",
        process.env.REACT_APP_UPLOAD_ASSETS_NAME
      );
      let response = await apiUploadImages(formData);
      if (response.status === 200)
        images = [...images, response.data?.secure_url];
    }
    setIsLoading(false);
    setImagesPreview((prev) => [...prev, ...images]);
    setPayload((prev) => ({ ...prev, images: [...prev.images, ...images] }));
  };

  const handleDeleteImage = (image) => {
    setImagesPreview((prev) => prev.filter((item) => item !== image));
    setPayload((prev) => ({
      ...prev,
      images: prev.images.filter((item) => item !== image),
    }));
  };

  const formatDate = (date) => {
    const padZero = (num) => (num < 10 ? '0' + num : num);
  
    const year = date.getFullYear();
    const month = padZero(date.getMonth() + 1); // Months are 0-based
    const day = padZero(date.getDate());
    const hours = padZero(date.getHours());
    const minutes = padZero(date.getMinutes());
    const seconds = padZero(date.getSeconds());
  
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const handleSubmit = async () => {
    const priceCodeArr = getCodes(
      +payload.priceNumber / Math.pow(10, 6),
      prices,
      1,
      15
    );
    const priceCode = priceCodeArr[0]?.code;
    const areaCodeArr = getCodesArea(+payload.areaNumber, areas, 0, 90);
    const areaCode = areaCodeArr[0]?.code;

    const now = new Date();
    const expiredDate = new Date(now.setMonth(now.getMonth() + 1)); // Add 30 days
    const formattedDate = `${expiredDate.getFullYear()}-${String(
      expiredDate.getMonth() + 1
    ).padStart(2, "0")}-${String(expiredDate.getDate()).padStart(
      2,
      "0"
    )} ${String(expiredDate.getHours()).padStart(2, "0")}:${String(
      expiredDate.getMinutes()
    ).padStart(2, "0")}:${String(expiredDate.getSeconds()).padStart(2, "0")}`;

    const finalPayload = {
      ...payload,
      priceCode,
      areaCode,
      userId: currentData.id,
      priceNumber: +payload.priceNumber / Math.pow(10, 6),
      target: payload.target || "Tất cả",
      label: `${
        categories?.find((item) => item.code === payload?.categoryCode)?.value
      } ${payload?.address?.split(",")[0]}`,
      expired: formatDate(new Date(Date.now() + 30 * 24 * 60 * 60 * 1000)),
    };

    const result = validate(finalPayload, setInvalidFields);
    console.log(result);

    if (result === 0) {
      if (dataEdit && isEdit) {
        finalPayload.postId = dataEdit?.id;
        finalPayload.attributesId = dataEdit?.attributesId;
        finalPayload.imagesId = dataEdit?.imagesId;
        finalPayload.overviewId = dataEdit?.overviewId;

        const response = await apiUpdatePost(finalPayload);
        console.log(response);

        if (response?.data.err === 0) {
          Swal.fire(
            "Thành công",
            "Đã chỉnh sửa bài đăng thành công",
            "success"
          ).then(() => {
            resetPayload();
            dispatch(resetDataEdit());
            nav("/");
          });
        } else {
          Swal.fire("Oops!", "Có lỗi gì đó", "error");
        }
      } else {
        const response = await apiCreatePost({
          ...finalPayload,
          expired: formatDate(new Date(Date.now() + 30 * 24 * 60 * 60 * 1000)),
        });
        if (response?.data.err === 0) {
          Swal.fire("Thành công", "Đã thêm bài đăng mới", "success").then(
            () => {
              resetPayload();
              nav("/");
            }
          );
        } else {
          Swal.fire("Oops!", "Có lỗi gì đó", "error");
        }
      }
    }
  };

  const resetPayload = () => {
    setPayload({
      categoryCode: "",
      title: "",
      priceNumber: 0,
      areaNumber: 0,
      images: "",
      address: "",
      priceCode: "",
      areaCode: "",
      description: "",
      target: "",
      province: "",
      expired: null,
    });
  };

  const fetchCoordinates = async (address) => {
    try {
      const response = await axios.get(
        `https://nominatim.openstreetmap.org/search`,
        {
          params: {
            q: address,
            format: "json",
            limit: 1,
          },
        }
      );
      if (response.data.length > 0) {
        const { lat, lon } = response.data[0];
        setCoords({ lat: parseFloat(lat), lng: parseFloat(lon) });
      }
    } catch (error) {
      console.error("Error fetching coordinates:", error);
    }
  };

  useEffect(() => {
    if (payload.address) {
      fetchCoordinates(payload.address);
    }
  }, [payload.address]);

  const ResetMap = () => {
    const map = useMap();
    useEffect(() => {
      if (coords.lat && coords.lng) {
        map.setView([coords.lat, coords.lng], 13);
      }
    }, [coords, map]);
    return null;
  };

  return (
    <div className="px-6">
      <h1 className="text-3xl font-medium py-4 border-b border-gray-200">
        {isEdit ? "Chỉnh sửa tin đăng" : "Đăng tin mới"}
      </h1>
      <div className="flex gap-4">
        <div className="py-4 flex flex-col gap-8 flex-auto">
          <Address
            invalidFields={invalidFields}
            setInvalidFields={setInvalidFields}
            payload={payload}
            setPayload={setPayload}
          />
          <Overview
            invalidFields={invalidFields}
            setInvalidFields={setInvalidFields}
            payload={payload}
            setPayload={setPayload}
          />
          <div className="w-full mb-6">
            <h2 className="font-semibold text-xl py-4">Hình ảnh</h2>
            <small>Cập nhật hình ảnh rõ ràng sẽ cho thuê nhanh hơn</small>
            <div className="w-full">
              <label
                className="w-full border-2 h-[200px] my-4 gap-4 flex flex-col items-center justify-center border-gray-400 border-dashed rounded-md"
                htmlFor="file"
              >
                {isLoading ? (
                  <Loading />
                ) : (
                  <div className="flex flex-col items-center justify-center">
                    <BsCameraFill color="blue" size={50} />
                    Thêm ảnh
                  </div>
                )}
              </label>
              <input
                onChange={handleFiles}
                hidden
                type="file"
                id="file"
                multiple
              />
              <small className="text-red-500 block w-full">
                {invalidFields?.some((item) => item.name === "images") &&
                  invalidFields?.find((item) => item.name === "images")
                    ?.message}
              </small>
              <div className="w-full">
                <h3 className="font-medium py-4">Ảnh đã chọn</h3>
                <div className="flex gap-4 items-center">
                  {imagesPreview?.map((item) => (
                    <div key={item} className="relative w-1/3 h-1/3 ">
                      <img
                        src={item}
                        alt="preview"
                        className="w-full h-full object-cover rounded-md"
                      />
                      <span
                        title="Xóa"
                        onClick={() => handleDeleteImage(item)}
                        className="absolute top-0 right-0 p-2 cursor-pointer bg-gray-300 hover:bg-gray-400 rounded-full"
                      >
                        <ImBin />
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <Button
            onClick={handleSubmit}
            text={isEdit ? "Cập nhật" : "Tạo mới"}
            bgColor="bg-green-600"
            textColor="text-white"
          />
        </div>
        <div className="w-[30%] flex-none h-[300px] pt-12">
          <MapContainer
            center={coords}
            zoom={13}
            scrollWheelZoom={false}
            style={{ height: "300px", width: "100%" }}
          >
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <Marker position={coords} />
            <ResetMap />
          </MapContainer>
          <div className="mt-8 bg-orange-100 text-orange-900 rounded-md p-4">
            <h4 className="text-xl font-medium mb-4">Lưu ý tin đăng</h4>
            <ul className="text-sm list-disc pl-6 text-justify">
              {attention.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreatePost;
